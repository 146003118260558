import pjax from "jquery-pjax";
import Index from './components/index.js'
import News from './components/news.js'
import About from './components/about.js'
import Lineup from './components/lineup.js'
import Parts from './components/parts.js'
import Gutting from './components/gutting.js'
import Howto from './components/howto.js'
import Company from './components/company.js'
import Delivery from './components/delivery.js'

/*
@
*/

$(document).on("click", "#NavOpen", function () {
	$("#Nav").addClass("is-open");
	$(this).addClass("is-open");
})
$(document).on("click", "#NavClose", function () {
	$("#Nav").removeClass("is-open");
	$("#NavOpen").removeClass("is-open");
})

let Fixed = false;
$(window).on('scroll', function () {
	if ($(window).scrollTop() >= $("#MainImage").outerHeight() - $("#Header").outerHeight()) {
		// console.log("a");
		$("#Header").addClass("Fixed");
	} else {
		$("#Header").removeClass("Fixed");
	}

})

/* */
$(function () {
	pageSetting();
	//PJAX
	var nowHash;
	$(document).on('click', 'a.Link', function (e) {
		e.preventDefault();
		var href = $(this).attr('href');
		// $('a[href^=http]').not('[href*="'+location.hostname+'"]')

		// console.log(location.hostname);

		if (href.match(/#/i)) {
			var hash = href.split('#');
			nowHash = hash[1];
			href = hash[0];
		} else {
			nowHash = "";
		}

		$('.Loading').removeClass('none');
		$('.Loading--Bar').animate({ 'width': '100%' }, 625);
		$('.Loading--White').delay(125).animate({ 'width': '100%' }, 625, function () {
			/*
			if (menuFlg == true) {
					menuAnimation();
			}
			*/
			$.pjax({
				url: href,
				container: '.PageContents',
				fragment: '.PageContents',
				//scrollTo: 0,
				timeout: 30000
			});


		});
	});

	$(document).on('pjax:end', function () {

		$('.Loading--Inner').delay(250).animate({ 'width': '0' }, 625, function () {
			$('.Loading').addClass('none');
			$('.Loading--Inner').css({ 'width': '' });
			$('.Loading--Bar').css({ 'width': '' });
			$('.Loading--White').css({ 'width': '' });
		});
		pageSetting();
		var newPath = window.location.pathname + window.location.search;
		//ga('send', 'pageview', newPath);
	});

});
function pageSetting() {
	// console.log("n");
	var nowUrl = location.href;
	console.log(nowUrl);
	$("a[href^='#'].Anker").click(function () {
		var t; return t = $($(this).attr("href")), 0 != t.length ? ($("html, body").animate({
			scrollTop: t.offset().top - $("#Header").outerHeight()
		}, 2500), !1) : void 0
	})
	if (nowUrl.match(/news/)) {
		News();
	} else if (nowUrl.match(/about/)) {
		About();
	} else if (nowUrl.match(/lineup/)) {
		Lineup();
	} else if (nowUrl.match(/parts/)) {
		Parts();
	} else if (nowUrl.match(/gutting/)) {
		Gutting();
	} else if (nowUrl.match(/howto/)) {
		Howto();
	} else if (nowUrl.match(/company/)) {
		Company();
	} else if (nowUrl.match(/delivery/)) {
		Delivery();
	} else if (nowUrl.match(/shop/)) {
		$("#OnlineShopPage").css({
			height: $(window).height() - $("#MainImage").outerHeight() - $("#Footer").outerHeight() - 110
		})

		$(window).on("resize", function () {
			$("#OnlineShopPage").css({
				height: $(window).height() - $("#MainImage").outerHeight() - $("#Footer").outerHeight() - 110
			})
		})
	} else {
		Index();
	}

}
