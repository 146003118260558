export default function () {
  /*
  @
  */
  var controller = new ScrollMagic.Controller();

  $('#PartsList .Item').each(function () {
    const tween = new TimelineMax().add([

      new TimelineMax().staggerFromTo($(this), 1, {
        opacity: 0,
        y: 50,
        ease: Back.easeIn.config(2)
      }, {
        y: 0,
        opacity: 1
      }, 0.75)
    ]);

    //シーンを作成
    const scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 'onEnter',
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({ name: "this" })
      .addTo(controller);
  });


  /*
  @ PartsBlock
  */
  var PartsBlocktween = new TimelineMax().add([

    TweenMax.fromTo("#PartsBlock", 1, {
      opacity: 0,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#PartsBlock",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(PartsBlocktween)
    //.addIndicators({ name: "PartsBlock" })
    .addTo(controller);
}
