export default function () {
	var controller = new ScrollMagic.Controller();
	/*
	@ Introductiontween
	*/
	TweenMax.fromTo("#Introduction .Title", 1, {
		opacity: 0,
		x: -100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		opacity: 1,
		x: 0,
		z: 0
	})
	TweenMax.fromTo("#Introduction .Body", 1, {
		opacity: 0,
		x: 100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		delay: 0.5,
		opacity: 1,
		x: 0,
		z: 0
	})

	/*
	@ LineupType1tween
	*/
	var LineupType1tween = new TimelineMax().add([

		TweenMax.fromTo("#Title1", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1
		}),
		TweenMax.fromTo("#BeefTitle", 1, {
			opacity: 0,
			y: 100,
			z: -1,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.25,
			opacity: 1,
			y: 0,
			z: 0
		})
	]);
	var LineupType1 = new ScrollMagic.Scene({
		triggerElement: "#Title1",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(LineupType1tween)
		//.addIndicators({ name: "LineupType1 #Title1" })
		.addTo(controller);

	/*
	@ BeefListtween
	*/

	$('.Lineup--Type1__List .Item').each(function () {
		const tween = new TimelineMax().add([

			new TimelineMax().staggerFromTo($(this), 1, {
				opacity: 0,
				y: 50,
				ease: Back.easeIn.config(2)
			}, {
				y: 0,
				opacity: 1
			}, 0.75)
		]);

		//シーンを作成
		const scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 'onEnter',
			reverse: false
		})
			.setTween(tween)
			//.addIndicators({ name: "this" })
			.addTo(controller);
	});


	/*
	var BeefListtween = new TimelineMax().add([

			new TimelineMax().staggerFromTo("#BeefList .Item", 1, {
					opacity: 0,
					y: 50,
					ease: Back.easeIn.config(2)
			}, {
					y: 0,
					opacity: 1
			}, 0.5)
	]);
	var BeefList = new ScrollMagic.Scene({
			triggerElement: "#BeefList",
			triggerHook: 'onEnter',
			reverse: false
	})
			.setTween(BeefListtween)
			//.addIndicators({ name: "BeefList" })
			.addTo(controller);
*/
	/*
	@ PorkTitletween
	*/
	var PorkTitletween = new TimelineMax().add([

		TweenMax.fromTo("#PorkTitle", 1, {
			opacity: 0,
			y: 100,
			z: -1,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1,
			y: 0,
			z: 0
		})
	]);
	var PorkTitle = new ScrollMagic.Scene({
		triggerElement: "#PorkTitle",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(PorkTitletween)
		//.addIndicators({ name: "PorkTitle" })
		.addTo(controller);


	/*
	@ PorkListtween
	*/
	/*
	var PorkListtween = new TimelineMax().add([

			new TimelineMax().staggerFromTo("#PorkList .Item", 1, {
					opacity: 0,
					y: 50,
					ease: Back.easeIn.config(2)
			}, {
					y: 0,
					opacity: 1
			}, 0.5)
	]);
	var PorkList = new ScrollMagic.Scene({
			triggerElement: "#PorkList",
			triggerHook: 'onEnter',
			reverse: false
	})
			.setTween(PorkListtween)
			//.addIndicators({ name: "PorkList" })
			.addTo(controller);
*/


	/*
	@ Noshitween
	*/
	var Noshitween = new TimelineMax().add([

		new TimelineMax().staggerFromTo("#Noshi", 1, {
			opacity: 0,
			y: 100,
			ease: Back.easeIn.config(2)
		}, {
			y: 0,
			opacity: 1
		}, 0.5)
	]);
	var Noshi = new ScrollMagic.Scene({
		triggerElement: "#Noshi",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(Noshitween)
		//.addIndicators({ name: "Chicken" })
		.addTo(controller);



	/*
	@ Sealtween
	*/
	var Sealtween = new TimelineMax().add([

		new TimelineMax().staggerFromTo("#Seal", 1, {
			opacity: 0,
			y: 100,
			ease: Back.easeIn.config(2)
		}, {
			y: 0,
			opacity: 1
		}, 0.5)
	]);
	var Seal = new ScrollMagic.Scene({
		triggerElement: "#Seal",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(Sealtween)
		//.addIndicators({ name: "Chicken" })
		.addTo(controller);

	/*
	@ Chickentween
	*/
	var Chickentween = new TimelineMax().add([

		new TimelineMax().staggerFromTo("#ChickenTitle", 1, {
			opacity: 0,
			y: 100,
			ease: Back.easeIn.config(2)
		}, {
			y: 0,
			opacity: 1
		}, 0.5)
	]);
	var Chicken = new ScrollMagic.Scene({
		triggerElement: "#ChickenTitle",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(Chickentween)
		//.addIndicators({ name: "Chicken" })
		.addTo(controller);
	/*
	@ GoodsListtween
	*/
	var GoodsListtween = new TimelineMax().add([
		TweenMax.fromTo("#Title2", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1
		}),

		TweenMax.staggerFromTo("#GoodsList .Item", 1, {
			opacity: 0,
			x: 50,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.5,
			x: 0,
			opacity: 1,

		}, 0.75)
	]);
	var GoodsList = new ScrollMagic.Scene({
		triggerElement: "#GoodsList",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(GoodsListtween)
		//.addIndicators({ name: "GoodsList" })
		.addTo(controller);

	/*
	@ BeefSubTitletween
	*/
	var BeefSubTitletween = new TimelineMax().add([

		new TimelineMax().staggerFromTo("#BeefSubTitle", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1
		}, 0.5)
	]);
	var BeefSubTitle = new ScrollMagic.Scene({
		triggerElement: "#BeefSubTitle",
		triggerHook: 'onEnter',
		reverse: false
	})
		.setTween(BeefSubTitletween)
		//.addIndicators({ name: "BeefSubTitle" })
		.addTo(controller);

	/*
	@ GoodsSubTitletween
	*/
	var GoodsSubTitletween = new TimelineMax().add([

		new TimelineMax().staggerFromTo("#GoodsSubTitle", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1
		}, 0.5)
	]);
	var GoodsSubTitle = new ScrollMagic.Scene({
		triggerElement: "#GoodsSubTitle",
		triggerHook: 'onEnter',
		reverse: false,
	})
		.setTween(GoodsSubTitletween)
		//.addIndicators({ name: "GoodsSubTitle" })
		.addTo(controller);
}
