export default function () {
  /*
  @
  */
  var controller = new ScrollMagic.Controller();
  /*
  @ Newstween
  */
  TweenMax.fromTo("#News .Title", 1, {
    opacity: 0,
    x: -100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    opacity: 1,
    x: 0,
    z: 0
  })
  TweenMax.fromTo("#News .Body,#News .List", 1, {
    opacity: 0,
    x: 100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    delay: 0.5,
    opacity: 1,
    x: 0,
    z: 0
  })
}
