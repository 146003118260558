
export default function () {
	/*
	@ TopPageAnimation
	*/
	if ($("#TopPageMainImage").length) {
		var $window = $(window).width();
		var $height = $(window).height();
		if ($window < 640) {
			$("#TopPageMainImage").css({
				height: $height - ($("#Header").outerHeight() * 2) + $("#Header").outerHeight(),
				"margin-top": $("#Header").outerHeight(),
				"margin-bottom": $("#Header").outerHeight()
			})
		} else {
			$("#TopPageMainImage").css({
				height: $height - ($("#Header").outerHeight() * 2),
				"margin-top": $("#Header").outerHeight(),
				"margin-bottom": $("#Header").outerHeight()
			})
		}
		if ($height >= 736) {
			$("#TopPageMainImage").css({ height: $height - ($("#Header").outerHeight() * 2) })
		}
		$(window).on("resize", function () {
			$window = $(window).width();
			$height = $(window).height();
			if ($window < 640) {
				$("#TopPageMainImage").css({
					height: $height - ($("#Header").outerHeight() * 2) + $("#Header").outerHeight(),
					"margin-top": $("#Header").outerHeight(),
					"margin-bottom": $("#Header").outerHeight()
				})
			} else {
				$("#TopPageMainImage").css({
					height: $height - ($("#Header").outerHeight() * 2),
					"margin-top": $("#Header").outerHeight(),
					"margin-bottom": $("#Header").outerHeight()
				})
			}
			if ($height >= 700) {
				$("#TopPageMainImage").css({ height: $height - ($("#Header").outerHeight() * 2) })
			}
		})
		// $("#TopPageAnimation .Line").addClass("is-active");
		new TimelineMax().to("#TopPageAnimation .Line", 1, {
			scaleX: 0
		}).add([
			TweenMax.to("#TopPageAnimation .Top", 2.75, {
				scaleY: 0,
				ease: "Expo.easeInOut"
			}),
			TweenMax.to("#TopPageAnimation .Bottom", 2.75, {
				scaleY: 0,
				ease: "Expo.easeInOut"
			})
		]).add([
			TweenMax.to("#TopPageMainImage .Left", 3, {
				x: "-100%",
				ease: "Expo.easeInOut",
				//delay: 0.25,
				//delay: -1
			}),
			TweenMax.to("#TopPageMainImage .Right", 3, {
				x: "100%",
				ease: "Expo.easeInOut",
				//delay: 0.25
				//delay: -1
			}),
			TweenMax.to("#TopPageMainImage .Image", 3, {
				width: "100%",
				height: "100%",
				ease: "Expo.easeInOut",
				//delay: 0.25
				//delay: -1
			})
		]).to("#TopPageAnimation,#TopPageMainImage .Logo", 0, {
			visibility: "hidden"
		})
	}


	if ($("#TopPageAbout").length) {

		function playVideos(videos) {
			const startPosition = $(window).scrollTop() + $(window).height();
			videos.each(function (index) {
				if (startPosition > ($(this).offset().top + $(window).height() / 1.5)) {
					if (count === 0) {
						$(this).get(0).play();
						console.log("スタート")
						count++;
					}
				}
			});
		}

		let count = 0;
		playVideos($('#video'));
		$(window).on('scroll', function () {
			playVideos($('#video'));
		});

		var controller = new ScrollMagic.Controller();

		/*
		@ TopPageAboutPhototween
		*/
		var TopPageAboutPhototween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageAbout .Photo", 1, {
				opacity: 0,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
			})
		]);
		var TopPageAboutPhotoscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageAbout .Photo",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageAboutPhototween)
			//.addIndicators({ name: "TopPageAboutPhoto" })
			.addTo(controller);

		/*
		@ TopPageAbouttween
		*/
		var TopPageAbouttween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageAbout .Title", 1, {
				opacity: 0,
				x: 100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
				x: 0,
				z: 0
			}),
			TweenMax.fromTo("#TopPageAbout .Text", 1, {
				opacity: 0,
				x: -100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				delay: 0.25,
				opacity: 1,
				x: 0,
				z: 0
			})

		]);
		var TopPageAboutscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageAbout .Title",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageAbouttween)
			//.addIndicators({ name: "TopPageAbout" })
			.addTo(controller);

		/* ------------------------------ */

		/*
		@ TopPageGuttingPhototween
		*/
		var TopPageGuttingPhototween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageGutting .Photo", 1, {
				opacity: 0,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
			})
		]);
		var TopPageGuttingPhotoscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageGutting .Photo",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageGuttingPhototween)
			//.addIndicators({ name: "TopPageGuttingPhoto" })
			.addTo(controller);

		/*
		@ TopPageGuttingtween
		*/
		var TopPageGuttingtween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageGutting .Title", 1, {
				opacity: 0,
				x: -100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
				x: 0,
				z: 0
			}),
			TweenMax.fromTo("#TopPageGutting .Text", 1, {
				opacity: 0,
				x: 100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				delay: 0.25,
				opacity: 1,
				x: 0,
				z: 0
			})

		]);
		var TopPageGuttingscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageGutting .Title",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageGuttingtween)
			//.addIndicators({ name: "TopPageGutting" })
			.addTo(controller);


		/* ------------------------------ */

		/*
		@ TopPageShopPhototween
		*/
		var TopPageShopPhototween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageShop .Photo", 1, {
				opacity: 0,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
			})
		]);
		var TopPageShopPhotoscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageShop .Photo",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageShopPhototween)
			//.addIndicators({ name: "TopPageShopPhoto" })
			.addTo(controller);

		/*
		@ TopPageShoptween
		*/
		var TopPageShoptween = new TimelineMax().add([
			TweenMax.fromTo("#TopPageShop .Title", 1, {
				opacity: 0,
				x: -100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				opacity: 1,
				x: 0,
				z: 0
			}),
			TweenMax.fromTo("#TopPageShop .Text", 1, {
				opacity: 0,
				x: 100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				delay: 0.25,
				opacity: 1,
				x: 0,
				z: 0
			}),

			TweenMax.fromTo("#TopPageShop .TopPage--Instagram", 1, {
				opacity: 0,
				y: 100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				delay: 1,
				opacity: 1,
				y: 0,
				z: 0
			}),

			TweenMax.fromTo("#TopPageShop .Map", 1, {
				opacity: 0,
				y: 100,
				z: -1,
				ease: Back.easeIn.config(2)
			}, {
				delay: 1,
				opacity: 1,
				y: 0,
				z: 0
			})

		]);
		var TopPageShopscene = new ScrollMagic.Scene({
			triggerElement: "#TopPageShop .Title",
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(TopPageShoptween)
			//.addIndicators({ name: "TopPageShop" })
			.addTo(controller);
	}

}
