export default function () {
	/*
	@
	*/
	var controller = new ScrollMagic.Controller();
	/*
	@ Introductiontween
	*/
	TweenMax.fromTo("#Introduction .Title", 1, {
		opacity: 0,
		x: -100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		opacity: 1,
		x: 0,
		z: 0
	})
	TweenMax.fromTo("#Introduction .Body", 1, {
		opacity: 0,
		x: 100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		delay: 0.5,
		opacity: 1,
		x: 0,
		z: 0
	})

	/*
	@ Type1tween
	*/
	var Type1tween = new TimelineMax().add([

		TweenMax.fromTo("#Type1 h2", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Type1",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Type1tween)
		//.addIndicators({ name: "Type1" })
		.addTo(controller);




	$('#Recipe .Item').each(function () {
		const tween = new TimelineMax().add([

			new TimelineMax().staggerFromTo($(this), 1, {
				opacity: 0,
				y: 50,
				ease: Back.easeIn.config(2)
			}, {
				y: 0,
				opacity: 1
			}, 0.75)
		]);

		//シーンを作成
		const scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 'onCenter',
			reverse: false
		})
			.setTween(tween)
			//.addIndicators({ name: "this" })
			.addTo(controller);
	});
	/*
	@ Type2tween
	*/
	var Type2tween = new TimelineMax().add([

		TweenMax.fromTo("#Type2 h2", 1, {
			opacity: 0,
			ease: Back.easeIn.config(2)
		}, {
			opacity: 1,
		}), TweenMax.fromTo("#Type2 h2+p", 1, {
			opacity: 0,
			y: 50,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.25,
			y: 0,
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Type2",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Type2tween)
		//.addIndicators({ name: "Type2" })
		.addTo(controller);

	/*
	@ Recipe Item
	*/

	$(document).on('click', '#Recipe .Btn', function () {
		$(this).parent().next('.Howto--Recipe__Body').stop().slideToggle();
		$(this).toggleClass('is-active');
	});

	/*
	@ Scene1
	*/
	var Scene1tween = new TimelineMax().add([

		TweenMax.fromTo("#Scene1 .Title", 1, {
			opacity: 0,
			x: 100,
			ease: Back.easeIn.config(2)
		}, {
			x: 0,
			opacity: 1,
		}), TweenMax.fromTo("#Scene1 .Body", 1, {
			opacity: 0,
			x: -100,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.25,
			x: 0,
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Scene1",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Scene1tween)
		//.addIndicators({ name: "Scene1" })
		.addTo(controller);



	/*
	@ Scene2
	*/
	var Scene2tween = new TimelineMax().add([

		TweenMax.fromTo("#Scene2 .Title", 1, {
			opacity: 0,
			x: -100,
			ease: Back.easeIn.config(2)
		}, {
			x: 0,
			opacity: 1,
		}), TweenMax.fromTo("#Scene2 .Body", 1, {
			opacity: 0,
			x: 100,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.25,
			x: 0,
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Scene2",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Scene2tween)
		//.addIndicators({ name: "Scene2" })
		.addTo(controller);



	/*
	@ Scene3
	*/
	var Scene3tween = new TimelineMax().add([

		TweenMax.fromTo("#Scene3 .Title", 1, {
			opacity: 0,
			x: 100,
			ease: Back.easeIn.config(2)
		}, {
			x: 0,
			opacity: 1,
		}), TweenMax.fromTo("#Scene3 .Body", 1, {
			opacity: 0,
			x: -100,
			ease: Back.easeIn.config(2)
		}, {
			delay: 0.25,
			x: 0,
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Scene3",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Scene3tween)
		//.addIndicators({ name: "Scene3" })
		.addTo(controller);


}
