export default function () {
	console.log("a")
	/*
	@
	*/

	function playVideos(videos) {
		const startPosition = $(window).scrollTop() + $(window).height();
		videos.each(function (index) {

			if (count === 0) {
				$(this).get(0).play();
				console.log("スタート")
				// count++;
			}
			if (startPosition > ($(this).offset().top + $(window).height() / 1.5)) {
			}
		});
	}

	let count = 0;
	playVideos($('#video'));
	$(window).on('scroll', function () {
		playVideos($('#video'));
	});
	var controller = new ScrollMagic.Controller();

	$('#DelicaList .Item').each(function () {
		const tween = new TimelineMax().add([

			new TimelineMax().staggerFromTo($(this), 1, {
				opacity: 0,
				y: 50,
				ease: Back.easeIn.config(2)
			}, {
				y: 0,
				opacity: 1
			}, 0.75)
		]);

		//シーンを作成
		const scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 'onEnter',
			reverse: false
		})
			.setTween(tween)
			//.addIndicators({ name: "this" })
			.addTo(controller);


		new ScrollMagic.Scene({
			triggerElement: '#Order',
			triggerHook: 'onEnter',
			reverse: true
		})
			.setClassToggle('.Delica--Howto', 'is-none')
			.addTo(controller);
	});
}
