export default function () {
	/*
	@
	*/
	var controller = new ScrollMagic.Controller();

	/*
	@ Introductiontween
	*/
	TweenMax.fromTo("#Introduction .Title", 1, {
		opacity: 0,
		x: -100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		opacity: 1,
		x: 0,
		z: 0
	})
	TweenMax.fromTo("#Introduction .Body", 1, {
		opacity: 0,
		x: 100,
		z: -1,
		ease: Back.easeIn.config(2)
	}, {
		delay: 0.5,
		opacity: 1,
		x: 0,
		z: 0
	})
	/*
	@ Tabletween
	*/
	var Tabletween = new TimelineMax().add([

		TweenMax.fromTo("#Table", 1, {
			opacity: 0,
			y: 100,
			ease: Back.easeIn.config(2)
		}, {
			y: 0,
			opacity: 1,
		})
	]);
	var Guttingscene = new ScrollMagic.Scene({
		triggerElement: "#Table",
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(Tabletween)
		//.addIndicators({ name: "Table" })
		.addTo(controller);


}
