export default function () {
  /*
  @
  */
  var controller = new ScrollMagic.Controller();
  /*
  @ Introductiontween
  */
  TweenMax.fromTo("#Introduction .Title", 1, {
    opacity: 0,
    x: -100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    opacity: 1,
    x: 0,
    z: 0
  })
  TweenMax.fromTo("#Introduction .Body", 1, {
    opacity: 0,
    x: 100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    delay: 0.5,
    opacity: 1,
    x: 0,
    z: 0
  })

  /*
  @ Techniquetween
  */
  var Techniquetween = new TimelineMax().add([

    TweenMax.fromTo("#Technique h2", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Technique p", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Techniquescene = new ScrollMagic.Scene({
    triggerElement: "#Technique",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Techniquetween)
    //.addIndicators({ name: "Technique" })
    .addTo(controller);

  /*
  @ Efforttween
  */
  var Efforttween = new TimelineMax().add([

    TweenMax.fromTo("#Effort h2", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Effort p", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Effort .Btn", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.75,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Effortscene = new ScrollMagic.Scene({
    triggerElement: "#Effort",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Efforttween)
    //.addIndicators({ name: "Effort" })
    .addTo(controller);

  /*
  @ About--Photo
  */
  $('.About--Photo').each(function () {
    const tween = new TimelineMax().add([

      new TimelineMax().staggerFromTo($(this), 1, {
        opacity: 0,
        y: 50,
        ease: Back.easeIn.config(2)
      }, {
        y: 0,
        opacity: 1
      }, 0.75)
    ]);

    //シーンを作成
    const scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 'onCenter',
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({ name: "this" })
      .addTo(controller);
  });

}
