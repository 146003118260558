export default function () {
  var controller = new ScrollMagic.Controller();

  /*
  @ Item1tween
  */
  TweenMax.fromTo("#Item1 .Photo", 1, {
    opacity: 0,
    x: -100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    opacity: 1,
    x: 0,
    z: 0
  })
  TweenMax.fromTo("#Item1 .Body,#Item1 h2", 1, {
    opacity: 0,
    x: 100,
    z: -1,
    ease: Back.easeIn.config(2)
  }, {
    delay: 0.5,
    opacity: 1,
    x: 0,
    z: 0
  })
  /*
  @ Item2tween
  */
  var Item2tween = new TimelineMax().add([

    TweenMax.fromTo("#Item2 .Photo", 1, {
      opacity: 0,
      x: 100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Item2 .Body,#Item2 h2", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#Item2",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Item2tween)
    //.addIndicators({ name: "Item2" })
    .addTo(controller);

  /*
  @ Item3tween
  */
  var Item3tween = new TimelineMax().add([

    TweenMax.fromTo("#Item3 .Photo", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Item3 .Body,#Item3 h2", 1, {
      opacity: 0,
      x: 100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#Item3",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Item3tween)
    //.addIndicators({ name: "Item3" })
    .addTo(controller);

  /*
  @ Item4tween
  */
  var Item4tween = new TimelineMax().add([

    TweenMax.fromTo("#Item4 .Photo", 1, {
      opacity: 0,
      x: 100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Item4 .Body,#Item4 h2", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#Item4",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Item4tween)
    //.addIndicators({ name: "Item4" })
    .addTo(controller);
  /*
  @ Item5tween
  */
  var Item5tween = new TimelineMax().add([

    TweenMax.fromTo("#Item5 .Photo", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Item5 .Body,#Item5 h2", 1, {
      opacity: 0,
      x: 100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#Item5",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Item5tween)
    //.addIndicators({ name: "Item5" })
    .addTo(controller);
  /*
  @ Item6tween
  */
  var Item6tween = new TimelineMax().add([

    TweenMax.fromTo("#Item6 .Photo", 1, {
      opacity: 0,
      x: 100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      opacity: 1,
      x: 0,
      z: 0
    }),
    TweenMax.fromTo("#Item6 .Body,#Item6 h2", 1, {
      opacity: 0,
      x: -100,
      z: -1,
      ease: Back.easeIn.config(2)
    }, {
      delay: 0.5,
      opacity: 1,
      x: 0,
      z: 0
    })
  ]);
  var Guttingscene = new ScrollMagic.Scene({
    triggerElement: "#Item6",
    triggerHook: 'onCenter',
    reverse: false
  })
    .setTween(Item6tween)
    //.addIndicators({ name: "Item6" })
    .addTo(controller);
}
